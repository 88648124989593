.iframe-container{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    height: 0;
  }
  .iframe-container iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }